import React from 'react';

import Contact from '../Pages/Contact';
import Home from '../Pages/Home';
import Projects from '../Pages/Projects';
import WebPages from '../Pages/WebPages';
import { MenuItem } from './interfaces';

// xs={<576px} sm={≥576px} md={≥768px} lg={≥992px} xl={≥1200px} xxl={≥1600px}
export const xs = 8;
export const sm = 7;
export const md = 6;
export const lg = 5;
export const xl = 4;
export const xxl = 3;

export const MENU_ITEMS: Array<MenuItem> = [
    {
        name: 'Home',
        path: '/',
        component: <Home />,
    },
    {
        name: 'Projects',
        path: '/projects',
        component: <Projects />,
    },
    {
        name: 'Web Pages',
        path: '/webpages',
        component: <WebPages />,
    },
    {
        name: 'Contact',
        path: '/contact',
        component: <Contact />,
    },
];
