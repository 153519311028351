import './Home.css';

import React from 'react';

function Home() {
    return (
        <div className="banner">
            <h1>Ing. Jiří Znoj</h1>
            <h1> Senior software engineer</h1>
        </div>
    );
}

export default Home;
