import { Card, Col, Row } from 'antd';
import React from 'react';

import { lg, md, sm, xl, xs, xxl } from './constants';

export const getCoveredInCard = (child: JSX.Element) => (
    <Col span={24}>
        <Row justify="center">
            <Col
                xs={{ span: xs * 3 }}
                sm={{ span: sm * 3 }}
                md={{ span: md * 3 }}
                lg={{ span: lg * 3 }}
                xl={{
                    span: xl * 3,
                }}
                xxl={{
                    span: xxl * 3,
                }}
                style={{ padding: '24px' }}
            >
                <Card hoverable={false}>{child}</Card>
            </Col>
        </Row>
    </Col>
);
