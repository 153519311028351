import './Projects.css';

import { Card, List, Row } from 'antd';
import React from 'react';

import { getCoveredInCard } from '../Utils/cover';
import { ListItem } from '../Utils/interfaces';

const PROJECTS: Array<ListItem> = [
    {
        link: 'https://gp.znoj.cz/',
        name: `All my projects`,
        description: 'Description of all my projects without source codes',
        icon: 'gp.png',
    },
    {
        link: 'https://gitlab.com/Znoj-WEB',
        name: `Web apps on GitLab`,
        description: 'Web projects on GitLab with public source code',
        icon: 'gitlab.svg',
    },
    {
        link: 'https://gitlab.com/Znoj-Desktop',
        name: `Desktop apps on GitLab`,
        description: 'Desktop projects on GitLab with public source code',
        icon: 'gitlab.svg',
    },
    {
        link: 'https://gitlab.com/Znoj-Mobile',
        name: `Mobile apps on GitLab`,
        description: 'Mobile projects on GitLab with public source code',
        icon: 'gitlab.svg',
    },
];

function Projects() {
    return (
        <React.Fragment>
            <Row justify="center">
                <h1>Projects</h1>
            </Row>
            {getCoveredInCard(
                <div>
                    <List
                        itemLayout="vertical"
                        size="large"
                        dataSource={PROJECTS}
                        footer={
                            <Row justify="center">
                                <a href="https://about.gitlab.com/press/press-kit/">Icon</a> by{' '}
                                <a href="https://about.gitlab.com/">GitLab</a> /{' '}
                                <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/">CC BY</a>
                            </Row>
                        }
                        renderItem={(item) => (
                            <List.Item
                                key={item.name}
                                extra={
                                    <Card hoverable>
                                        <a href={item.link}>
                                            <img
                                                className="projectIcon"
                                                src={`/images/projects/${item.icon}`}
                                                alt="icon"
                                            />
                                        </a>
                                    </Card>
                                }
                            >
                                <List.Item.Meta title={<a href={item.link}>{item.name}</a>} />
                                {item.description}
                            </List.Item>
                        )}
                    />
                </div>,
            )}
        </React.Fragment>
    );
}

export default Projects;
