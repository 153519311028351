import { Layout } from 'antd';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Navigation from './Components/Navigation';
import Home from './Pages/Home';
import { MENU_ITEMS } from './Utils/constants';
import { MenuItem } from './Utils/interfaces';

const { Content, Footer } = Layout;

function MainRouter() {
    return (
        <Layout>
            <Router>
                <Navigation />
                <Content className="site-layout" style={{ marginTop: 48 }}>
                    <Switch>
                        {MENU_ITEMS.filter((item: MenuItem) => item.path !== '/').map(
                            (item: MenuItem, index: number) => (
                                <Route path={item.path} key={index}>
                                    {item.component}
                                </Route>
                            ),
                        )}
                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                </Content>
            </Router>
            <Footer style={{ textAlign: 'center' }}>Ant Design ©2020 - Jiří Znoj</Footer>
        </Layout>
    );
}

export default MainRouter;
