import './Navigation.css';

import { Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { MENU_ITEMS } from '../Utils/constants';
import { MenuItem } from '../Utils/interfaces';

const { Header } = Layout;

function Navigation() {
    const location = useLocation();
    const [path, setPath] = useState('/');
    useEffect(() => {
        setPath(location.pathname);
    }, [location]);

    return (
        <Header style={{ position: 'fixed', width: '100%' }}>
            <Menu className="menu" theme="dark" mode="horizontal" selectedKeys={[path]}>
                {MENU_ITEMS.map((menuItem: MenuItem) => (
                    <Menu.Item key={menuItem.path}>
                        <Link to={menuItem.path}>{menuItem.name}</Link>
                    </Menu.Item>
                ))}
            </Menu>
        </Header>
    );
}

export default Navigation;
