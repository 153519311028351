import './WebPages.css';

import { Card, Divider, Row } from 'antd';
import React from 'react';

import { ListItem } from '../Utils/interfaces';

const WEB_PAGES: Array<ListItem> = [
    {
        link: 'https://dostalovi-svatba.cz',
        name: 'Svatba Dostálovi 2023',
        description: '(2023, TypeScript, Material-UI, React)',
        icon: 'svatbaD.jpg',
    },
    {
        link: 'https://clocks.znoj.cz',
        name: 'Game clocks 2023',
        description: '(2023, TypeScript, Ant Design, React)',
        icon: 'clocks.jpg',
    },
    {
        link: 'http://jedlickovi-svatba.cz',
        name: 'Svatba Jedličkovi 2022',
        description: '(2022, TypeScript, Material-UI, React)',
        icon: 'svatbaJ.jpg',
    },
    {
        link: 'https://svatba.znoj.cz',
        name: 'Svatba Znoj 2021',
        description: '(2021, TypeScript, Material-UI, React)',
        icon: 'svatbaZ.jpg',
    },
    {
        link: 'http://shipmonk.znoj.cz/',
        name: 'Single Container Packing 2020',
        description: '(2020, TypeScript, React, React Bootstrap, Redux)',
        icon: 'containerPacking.jpg',
    },
    {
        link: 'https://eventio.znoj.cz/',
        name: 'Eventio 2020',
        description: '(2020, TypeScript, Ant Design, React, Redux)',
        icon: 'eventio.jpg',
    },
    {
        link: 'https://znoj.cz',
        name: 'Znoj.cz 2020',
        description: '(2020, TypeScript, Ant Design, React)',
        icon: 'znoj20.jpg',
    },
    {
        link: 'https://workout.znoj.cz',
        name: 'Workout diary',
        description: '(2020, TypeScript, Ant Design, React, Redux, Firebase db)',
        icon: 'workout.jpg',
    },
    {
        link: 'https://gp.znoj.cz/Znoj-WEB/btc-check-2',
        name: 'Btc check 2',
        description: '(2020, TypeScript, Ant Design, React, Redux, Firebase db)',
        icon: 'btcheck2.jpg',
    },
    {
        link: 'https://stomatologie-klimes.cz/',
        name: "My dentist's official page",
        description: '(2019, JavaScript, Ant Design, React)',
        icon: 'zubar.jpg',
    },
    {
        link: 'https://gp.znoj.cz/Znoj-WEB/btc-check',
        name: 'BTC check',
        description: '(2019, JavaScript, PHP, Ant Design, React)',
        icon: 'btcheck.jpg',
    },
    {
        link: 'https://fe.znoj.cz/',
        name: 'Firebase RealTime db example',
        description: '(2019, JavaScript, Material-UI, React, Firebase db)',
        icon: 'fe.jpg',
    },
    {
        link: 'https://gp.znoj.cz',
        name: 'My Gitlab Projects',
        description: '(2019, JavaScript, Ant Design, React)',
        icon: 'gp.jpg',
    },
    {
        link: 'https://bookmarks.znoj.cz',
        name: 'Simple Shows bookmarks',
        description: '(2017, HTML, PHP, Material Design Lite)',
        icon: 'bookmarks.jpg',
    },
    {
        link: 'https://pvbps.znoj.cz',
        name: 'Computer viruses',
        description: '(2017, HTML, PHP, Materialize)',
        icon: 'pvbps.jpg',
    },
    {
        link: 'https://pou.znoj.cz',
        name: 'Computer defence and attack',
        description: '(2017, HTML, PHP, Materialize)',
        icon: 'pou.jpg',
    },
    {
        link: 'https://prfiq.znoj.cz',
        name: 'prfiq dashboard concept',
        description: '(2016, HTML, PHP, Materialize)',
        icon: 'prfiq.jpg',
    },
    {
        link: 'https://pr1.znoj.cz',
        name: 'Programming (C++)',
        description: '(2016, HTML, PHP, Materialize)',
        icon: 'pr1.jpg',
    },
    {
        link: 'https://znoj16.znoj.cz',
        name: 'My previous personal page',
        description: '(2016, HTML, PHP, Materialize)',
        icon: 'znoj16.jpg',
    },
    {
        link: 'https://movies.znoj.cz',
        name: 'Movies Collection',
        description: '(2015, HTML + Bootstrap)',
        icon: 'movies.jpg',
    },
    {
        link: 'https://leasio.znoj.cz',
        name: 'Leasio.cz',
        description: '(2015, Bootstrap + CSS)',
        icon: 'leasio.jpg',
    },
    {
        link: 'http://alda-knihy.cz/nakladatelstvi/',
        name: 'Alda book publishing',
        description: '(2015, WordPress)',
        icon: 'alda.jpg',
    },
    {
        link: 'http://jak.znoj.cz',
        name: 'J. A. Komensky fan page',
        description: '(2014, HTML)',
        icon: 'jak.jpg',
    },
    {
        link: 'http://junak.bludov.cz/',
        name: "Scout Bludov's official webpage",
        description: '(2013, Drupal)',
        icon: 'junakbludov.jpg',
    },
    {
        link: 'http://game.znoj.cz/',
        name: 'Android game presentation',
        description: '(2013, HTML + CSS - from template)',
        icon: 'game.jpg',
    },
    {
        link: 'http://zed.zaridi.to',
        name: 'Zed.zaridi.to',
        description: '(2012, PHP + CSS + MySQL)',
        icon: 'zed.jpg',
    },
    {
        link: 'http://fei.znoj.cz',
        name: 'Materials VŠB-FEI (+ Dominik Ther)',
        description: '(2012, PHP + CSS)',
        icon: 'fei.jpg',
    },
    {
        link: 'http://255old.znoj.cz',
        name: 'Wishes lists [design 2015]',
        description: '(2012, PHP + MySQL + Bootstrap)',
        icon: '255old.jpg',
    },
    {
        link: 'https://kolstejn.znoj.cz',
        name: "Kolstejn racing team's official page",
        description: '(2011, HTML + CSS)',
        icon: 'kolstejn.jpg',
    },
    {
        link: 'https://kone.znoj.cz',
        name: "Equestrian club's official page",
        description: '(2010, HTML)',
        icon: 'kone.jpg',
    },
    {
        link: 'https://junakold.znoj.cz',
        name: "Old Scout Bludov's official webpage",
        description: '(2009, HTML)',
        icon: 'junakold.jpg',
    },
    {
        link: 'https://zamecnictvi.znoj.cz',
        name: "Blacksmith Stastny's official page",
        description: '(2008, HTML)',
        icon: 'zamecnictvi.jpg',
    },
    {
        link: 'https://zs.znoj.cz',
        name: 'My first HTML page in primary school',
        description: '(2005, HTML)',
        icon: 'zs.jpg',
    },
];

function WebPages() {
    return (
        <React.Fragment>
            <Row justify="center">
                <h1>Web Pages</h1>
            </Row>
            <Row justify={'space-around'}>
                {WEB_PAGES.map((web, index: number) => (
                    <Card.Grid hoverable className="webPageCardGrid" key={index}>
                        <a href={web.link}>
                            <img src={`/images/webPages/${web.icon}`} alt={web.name} />
                            <Divider>{web.name}</Divider>
                            <div className="description">{web.description}</div>
                        </a>
                    </Card.Grid>
                ))}
            </Row>
        </React.Fragment>
    );
}

export default WebPages;
