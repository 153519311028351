import './Contact.css';

import { List, Row } from 'antd';
import React from 'react';

import { getCoveredInCard } from '../Utils/cover';
import { ListItem } from '../Utils/interfaces';

const CONTACTS: Array<ListItem> = [
    {
        link: '#',
        name: `email`,
        description: 'jiri[at]znoj[dot]cz',
        icon: 'mail.svg',
    },
    {
        link: 'https://www.linkedin.com/in/znoj/',
        name: `LinkedIn`,
        description: 'https://www.linkedin.com/in/znoj/',
        icon: 'in.svg',
    },
    {
        link: 'https://www.facebook.com/IriZnoj',
        name: `facebook`,
        description: 'https://www.facebook.com/IriZnoj',
        icon: 'fb.svg',
    },
];

function Contact() {
    return (
        <React.Fragment>
            <Row justify="center">
                <h1>Contact</h1>
            </Row>
            {getCoveredInCard(
                <div>
                    <List
                        itemLayout="vertical"
                        size="large"
                        dataSource={CONTACTS}
                        renderItem={(item) => (
                            <List.Item
                                key={item.name}
                                extra={
                                    <Row align="middle">
                                        <a href={item.link}>
                                            <img
                                                className="contactIcon"
                                                src={`/images/contact/${item.icon}`}
                                                alt="icon"
                                            />
                                        </a>
                                    </Row>
                                }
                            >
                                <List.Item.Meta title={<a href={item.link}>{item.name}</a>} />
                                <a href={item.link}>{item.description}</a>
                            </List.Item>
                        )}
                    />
                </div>,
            )}
        </React.Fragment>
    );
}

export default Contact;
